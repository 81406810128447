<template>
  <app-main>
    <template #headerText>
      <h3>Terms and Conditions</h3>
    </template>
    <el-row>
      <el-col class="pr-3">
        <span v-html="activity.term" />
       
      </el-col>
    </el-row>
  </app-main>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name:'AppTerms',
    
    data() {
        return {
            language: 'english',
            terms:''
        }
    },
    computed: {
        ...mapGetters(['activity']),

    },
   
}
</script>

<style lang="scss">
p,
ol,
li {
  text-align: justify;
  line-height: 2em;
}
.arabic {
  h4, p {
    direction: rtl;
  }
}

table {
  border: 1px solid #dee2e6;
  td {
    border-left: 1px solid #dee2e6;
  }
}
</style>
